<template>
  <div>
    <!-- Show the header option -->
    <div class="d-flex justify-end mb-6">
      <!-- Show the add button -->
      <v-btn
        depressed
        color="primary"
        :loading="isMakingRequest && !formData.id"
        :disabled="isMakingRequest && !formData.id"
        @click="shouldShowFormDialog = true"
      >
        <v-icon left>
          add
        </v-icon>

        Add Note
      </v-btn>
    </div>

    <!-- In case there are no tasks yet -->
    <template
      v-if="notes.length === 0"
    >
      <v-subheader class="d-flex justify-space-between primary white--text">
        <span>
          Notes
        </span>

        <span>
          0 notes
        </span>
      </v-subheader>

      <div
        class="white mt-0 px-4 d-flex justify-center"
      >
        <!-- Show the animation here -->
        <div style="max-width: 360px" class="mx-auto">
          <lottie-animation
            loop
            file="93121-no-data-preview.json"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <!-- Show the notes list here -->
      <v-list
        v-for="group in groups"
        :key="group.id"
        class="mb-4 pb-0"
        two-line
        subheader
      >
        <v-subheader
          :title="group.date"
          class="d-flex justify-space-between primary white--text"
          @click="toggleExpansion(group.id)"
        >
          <span>
            {{ dayjs(group.date).isToday() ? "Today" : (dayjs(group.date).isYesterday() ? "Yesterday" : dayjs(group.date).format("LL")) }}
          </span>

          <v-btn
            icon
            dark
          >
            <v-icon
              class="group-icon-container"
              :class="{ rotate: expandedGroups[group.id] }"
            >
              expand_more
            </v-icon>
          </v-btn>
        </v-subheader>

        <template v-if="expandedGroups[group.id]">
          <v-divider />

          <template
            v-for="(item, index) in group.items"
          >
            <div
              :key="item.id"
              class="d-flex align-start px-4 py-3"
            >
              <!-- User avatar in left -->
              <div>
                <user-avatar
                  :name="item.user.name"
                  :title="item.user.email"
                />
              </div>

              <!-- Show the content in between -->
              <div class="flex-grow-1 pl-4">
                <!-- Show the message title -->
                <div
                  v-if="item.title"
                  class="text-subtitle-2 font-weight-bold"
                >
                  {{  item.title  }}
                </div>

                <!-- Show the date message was written and written by -->
                <div class="text-caption">
                  <span :title="item.user.email">By {{ item.user.name }}</span> | {{ dayjs(item.created_at).format("LLL") }}

                  <template v-if="item.created_at !== item.updated_at">
                    &nbsp;| Updated {{ dayjs(item.updated_at).format("LLL") }}
                  </template>
                </div>

                <!-- Show the item content text -->
                <div class="mt-3">
                  <pre style="font-family: inherit;">{{ item.content }}</pre>
                </div>
              </div>

              <!-- Show the item actions here -->
              <v-menu
                transition="slide-x-reverse-transition"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list
                  dense
                  min-width="120"
                >
                  <v-list-item @click="formData = item; shouldShowFormDialog = true;">
                    <v-list-item-title>
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="selectedItem = item; shouldShowDeleteDialog = true;">
                    <v-list-item-title>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <v-divider v-if="index < (group.items.length - 1)" />
          </template>
        </template>
      </v-list>
    </template>

    <!-- Show the delete dialog here -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Do you want to delete this note?
        </v-card-title>

        <v-card-text>
          You cannot recover this later
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            dark
            depressed
            color="red"
            :disabled="isMakingRequest"
            :loading="isMakingRequest"
            @click="handleDeleteRequest"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the create/update dialog here -->
    <v-dialog
      v-model="shouldShowFormDialog"
      :persistent="isMakingRequest"
      max-width="500"
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          {{ formData.id ? "Update Note" : "Add Note" }}
        </v-card-title>

        <v-card-text class="pt-6">
          <!-- Title input field -->
          <v-text-field
            v-model="formData.title"
            :hide-details="!$v.formData.title.$anyError"
            :error-messages="$v.formData.title.$anyError ? ['Please enter a valid title'] : null"
            @blur="$v.formData.title.$touch"
            :disabled="isMakingRequest"
            placeholder="Optional"
            counter="200"
            label="Title"
            class="mb-4"
            outlined
            dense
          />

          <!-- Content input field -->
          <v-textarea
            v-model="formData.content"
            :hide-details="!$v.formData.content.$anyError"
            :error-messages="$v.formData.content.$anyError ? ['Please enter a valid note content'] : null"
            @blur="$v.formData.content.$touch"
            :disabled="isMakingRequest"
            counter="5000"
            label="Content"
            outlined
            dense
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowFormDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleSubmitRequest"
          >
            {{ formData.id ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Import helper functions
import { required, minLength, maxLength } from "vuelidate/lib/validators"

// Import children components
import UserAvatar from "@/blocks/common/UserAvatar.vue"

// Define the form generator function
const generateForm = (id = null) => ({
  id,
  title: "",
  content: ""
})

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachNotes",

  // Accept incoming data from parent
  props: {
    influencer: {
      type: Object,
      required: true
    },

    data: {
      type: Object,
      required: true
    }
  },

  // Register children components
  components: {
    UserAvatar,
    LottieAnimation
  },

  // Define local data variables
  data: () => ({
    // Whether or not the app is making a rest API request
    isMakingRequest: false,

    // Whether or not to show the create or update form
    shouldShowFormDialog: false,

    // Whether or not to show the delete dialog
    shouldShowDeleteDialog: false,

    // The form object for creating a new note
    formData: generateForm(),

    // The selectedModel item
    selectedItem: null,

    // Get the grouped items from here
    expandedGroups: {}
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get the note models related to this influencer
     *
     * @returns {Array}
     */
    notes() {
      return this.data.notes.filter((search) => search.campaign_report_influencer_id === this.influencer.id)
    },

    /**
     * Compute the note items into day wise groups
     *
     * @returns {Array}
     */
    groups() {
      // Create a local array to store items in groups
      const _groups = []

      // Go through each of the items
      for (const item of this.notes) {
        // Get the date string from this
        const date = dayjs(item.created_at).format("YYYY-MM-DD")

        // Check if there's already a group with these matching values
        const group = _groups.find((search) => search.date === date)

        // If found
        if (group) {
          // Append the item to it
          group.items.push(item)
        }
        // Otherwise
        else {
          // Create a new group
          const group = {
            date,
            id: "group-" + _groups.length,
            items: [item]
          }

          // Push it to the array
          _groups.push(group)

          // Add the value for expansion items
          this.$set(this.expandedGroups, group.id, typeof this.expandedGroups[group.id] === "boolean" ? this.expandedGroups[group.id] : true)
        }
      }

      // Return the final group items, sorted by time
      return _groups.sort((a, b) => dayjs(b.date).unix() - dayjs(a.date).unix())
    }
  },

  // Define vuelidate validations
  validations: {
    formData: {
      title: {
        maxLength: maxLength(200)
      },
      content: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(5000)
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Make a network request to create or update the notes model
     *
     * @returns {void}
     */
    async handleSubmitRequest() {
      // Run the validations
      await this.$v.formData.$touch()

      // Check if there's any error
      if (this.$v.formData.$anyError) {
        // End further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Find if it's going to be an update or create
      const isUpdate = (this.formData.id === null) ? false : true

      // Make the network request
      try {
        // Use the helper function
        const response = await axios({
          url: `/api/influencer-outreach/${this.data.model.id}/influencers/${this.influencer.id}/notes` + (isUpdate ? `/${this.formData.id}` : ""),
          method: isUpdate ? "PUT" : "POST",
          data: {
            title: this.formData.title,
            content: this.formData.content
          }
        })

        // Show a message
        this.$store.dispatch("toasts/add", { text: isUpdate ? "Note updated!" : "Note created!" })

        // Based on the request type
        if (isUpdate) {
          // Dispatch the Vuex action
          this.$store.dispatch("influencerOutreach/updateNote", response.data)
        }
        // Otherwise
        else {
          // Dispatch the Vuex action
          this.$store.dispatch("influencerOutreach/createNote", response.data)
        }

        // Reset the form
        this.formData = generateForm()
        this.$v.$reset()

        // Hide the dialog
        this.shouldShowFormDialog = false
      }
      // Catch the error
      catch (error) {
        // Log the error to console
        logger({ type: "InfluencerOutreach/InfluencerNotes Create/Update Error", error })

        // Show the error message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nevertheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Make a network request to delete the notes model
     *
     * @returns {void}
     */
    async handleDeleteRequest() {
      // If there's no selected item
      if (!this.selectedItem) {
        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Use the helper function
        await axios({
          url: `/api/influencer-outreach/${this.data.model.id}/influencers/${this.influencer.id}/notes/${this.selectedItem.id}`,
          method: "DELETE"
        })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Note deleted!" })

        // Dispatch the Vuex action
        this.$store.dispatch("influencerOutreach/deleteNote", this.selectedItem)

        // Reset the form
        this.selectedItem = null

        // Hide the dialog
        this.shouldShowDeleteDialog = false
      }
      // Catch the error
      catch (error) {
        // Log the error to console
        logger({ type: "influencerOutreach/InfluencerNotes Delete Error", error })

        // Show the error message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nevertheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Update the expansion state for the group item
     *
     * @param {Number} index
     * @returns {void}
     */
    toggleExpansion(groupId) {
      this.$set(this.expandedGroups, groupId, !this.expandedGroups[groupId])
    }
  }
}
</script>

<style lang="stylus">
.group-icon-container
  transition transform 0.1s ease-in

  &.rotate
    transform rotate(-180deg)
</style>