<template>
  <v-avatar
    :size="size"
    :color="computedColor"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <img
      v-if="src"
      :src="src ? proxyUrl(src) : '/img/gray-square.png'"
    />
    <span
      v-else
      class="white--text text-h6"
    >
      {{ name[0].toLocaleUpperCase() }}
    </span>
  </v-avatar>
</template>

<script>
// Define the colors list
const colors = [
  "secondary",
  "red darken-1",
  "pink darken-1",
  "purple darken-1",
  "deep-purple darken-1",
  "indigo darken-1",
  "blue darken-1",
  "light-blue darken-1",
  "cyan darken-1",
  "teal darken-1",
  "green darken-1",
  "amber darken-2",
  "orange darken-1",
  "deep-orange darken-1",
  "brown darken-1",
  "blue-grey darken-1"
]

// Export the SFC
export default {
  // Name of the component
  name: "UserAvatar",

  // Accept incoming data from parent
  props: {
    size: {
      type: [Number, String],
      required: false,
      default: 36
    },

    color: {
      type: String,
      required: false,
      default: "secondary"
    },

    src: {
      type: String,
      required: false,
      default: null
    },

    name: {
      type: String,
      required: false,
      default: "User"
    },

    autoColor: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  // Define computable readonly properties
  computed: {
    /**
     * Compute a background color for this avatar item
     *
     * @returns {String}
     */
    computedColor() {
      // If we're not supposed to auto generate a color
      if (!this.autoColor || !this.name) {
        return this.color
      }

      // Otherwise, get the character code
      const number = this.name.charCodeAt(0)

      // Divide by the colors we have
      const index = number % colors.length

      // Return that color
      return colors[index]
    }
  }
}
</script>
